module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#DFE0FD',

  primary: '#1644A8',
  primaryLight: '#3874F5',
  primaryDark: '#001E5E',

  secondary: '#D1365B',
  secondaryLight: '#FF5C82',
  secondaryDark: '#85152F',
};
